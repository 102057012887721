import axios from 'axios'
import url from '../url'
import {
    getArray, setQuery, getJson, setData,
} from '../resources/composerResource'

export default class ArtistRepository {
    async index(data) {
        const params = setQuery(data)
        const response = await axios.get(url('indexComposer'), { params })
        if (response.status === 200) {
            return getArray(response.data)
        }
    }

    async show(artisId) {
        const response = await axios.get(url('showComposer', { composer: artisId }))
        if (response.status === 200) {
            return getJson(response.data.data)
        }
    }

    async store(data) {
        const response = await axios.post(url('storeComposer'), data)
        if (response.status === 201) {
            return getJson(response.data)
        }
    }

    async update(composerId, data) {
        // const json = setData(data)
        const response = await axios.put(
            url('updateComposer', { composerId: composerId }),
            data,
        )
        if (response.status === 200) {
            return response.data.data
        }
    }

    async delete(userId) {
        await axios.delete(url('destroyUser', { user: userId }))
    }
}

import { SetPagination, SetQueriesObject } from "@/utils/setQueriesObject";

export const getJson = (data) => ({
    id: data.id,
    name: data.name,
    bio: data.bio,
    name_ar: data.name_ar,
    first_name_length: data.first_name_length,
    first_name: data.first_name,
    last_name: data.last_name,
    first_name_ar: data.first_name_ar,
    last_name_ar: data.last_name_ar,
    email: data.email,
    email_verified_at: data.email_verified_at,
    is_artist: data.is_artist,
    cell_number: data.cell_number,
    city_id: data.city_id,
    country_id: data.country_id,
    country_code: data.country_code,
    image: data.image,
    thumbnail: data.thumbnail,
    user_name: data.user_name,
    have_verify_tick: data.have_verify_tick,
});
export const getArray = ({ data, meta }) => {
    const pagination = SetPagination(meta);
    data = data.map((user) => getJson(user));
    return { data, pagination };
};
export const setData = (data) => {
    const formData = {
        first_name: data.first_name,
        last_name: data.last_name,
        first_name_ar: data.first_name_ar,
        last_name_ar: data.last_name_ar,
        bio: data.bio,
        city_id: data.city_id,
        country_id: data.country_id,
        email: data.email,
        file_batch_id: data.file_batch_id,
        is_artist: data.is_artist,
        role: data.role,
        cell_number: data.cell_number,
        user_name: data.user_name,
        have_verify_tick: data.have_verify_tick,
    };
    return formData;
};
export const setQuery = (data) => SetQueriesObject(data);

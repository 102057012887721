<template>
    <component :is="form === undefined ? 'div' : 'b-card'">
        <template>
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <b-col cols="12">
                        <b-media no-body>
                            <b-media-body class="mt-75 ml-75">
                                <b-row class="row justify-content-center">
                                    <b-col cols="12" sm="4">
                                        <!-- upload button -->

                                        <image-cropper v-model="fileForm" label="manager image" :url.sync="form.thumbnail" />
                                        <!--/ upload button -->
                                    </b-col>
                                </b-row>
                                <b-card-text class="row justify-content-center">Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
                            </b-media-body>
                        </b-media>
                    </b-col>
                    <b-row class="mt-3">
                        <b-col cols="12" sm="6" md="4">
                            <b-form-group label="Name" label-for="name">
                                <b-form-input id="name" v-model="form.name" placeholder="type slideshow name" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6" md="4">
                            <b-form-group label="Type" label-for="type">
                                <v-select :disabled="unEditable" v-model="form.slidable_type" :options="sourceOption" label="type" input-id="type" placeholder="Please first select the type" @input="fetchTypeList(form.slidable_type)" />
                            </b-form-group>
                        </b-col>

                        <b-col v-if="form.slidable_type == 'advertise'" cols="12" sm="6" md="4">
                            <b-form-group label="Advertise" label-for="type">
                                <b-form-input id="name" v-model="form.link" placeholder="Enter the Advertise Link" :loading="isLoading" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="">
                        <b-col v-if="form.slidable_type == 'store_items'" cols="12" sm="2" md="2">
                            <b-form-group label="search type" label-for="SearchItems">
                                <v-select v-model="searchTypeItem" :options="searchType" label="SearchItems" input-id="SearchItems" placeholder="which type you want to search?" :loading="isLoading" @input="fillStoreItems" />
                            </b-form-group>
                        </b-col>
                        <b-col v-if="form.slidable_type == 'store_items'" cols="12" sm="6" md="4">
                            <b-form-group label="StoreItems" label-for="Store Items">
                                <v-select v-model="form.slidable_id" :options="storeOptions" label="name" input-id="StoreItems" placeholder="Choose the store item" :loading="isLoading" @search="filterStoreItems" :reduce="(val) => val.id" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="row justify-content-center">
                        <!-- Form Actions -->
                        <b-col cols="12">
                            <div class="d-flex mt-2">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mr-2" type="submit">
                                    {{ slideShowId ? "update" : "add" }}
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </template>
        <div class="text-center">
            <AddSongModal />
        </div>
    </component>
</template>

<script>
import { BFormCheckbox, BFormSelect, BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import { ref } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { makeid } from "@/utils/math";
import useSlideShowList from "./useSlideShowList";
import useGenre from "../../genre/useGenre";
import useFile from "../../file/useFile";
import ArtistRepository from "@/abstraction/repository/artistRepository";
import MusicRepository from "@/abstraction/repository/musicRepository";
import VideoRepository from "@/abstraction/repository/videoRepository";
import PlaylistRepository from "@/abstraction/repository/playListRepository";
import ComposerRepository from "@/abstraction/repository/composerRepository";
import StoreRepository from "@/abstraction/repository/storeRepository";
import AddSongModal from "./AddSong.vue";

const RANDOM_TOKEN = makeid(50);

export default {
    components: {
        AddSongModal,
        BTab,
        BFormInput,
        BFormCheckbox,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BFormSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    setup() {
        let blankForm = {
            name: "",
            slidable_type: "",
            slidable_id: null,
            link: null,
            file_batch_id: RANDOM_TOKEN,
        };
        const artistRepository = new ArtistRepository();
        const musicRepository = new MusicRepository();
        const videoRepository = new VideoRepository();
        const playlistRepository = new PlaylistRepository();
        const composerRepository = new ComposerRepository();
        const storeRepository = new StoreRepository();
        const slideShowId = ref(null);
        const isLoading = ref(false);
        const artistOption = ref(undefined);
        const musicOption = ref(undefined);
        const videoOption = ref(undefined);
        const playlistOption = ref(undefined);
        const composerOption = ref(undefined);
        const unEditable = ref(false);
        const { storeFile, fileForm } = useFile(RANDOM_TOKEN, "main_image", "store_slide_shows");
        const { storeSlideShow, updateSlideshow, showSlideshow } = useSlideShowList();
        const form = ref(JSON.parse(JSON.stringify(blankForm)));
        const sourceOption = ref(["advertise", "store_items"]);
        const resetData = () => {
            form.value = JSON.parse(JSON.stringify(blankForm));
        };
        const searchType = ref(["badge", "gift", "sticker_pack"]);
        const searchTypeItem = ref();
        const storeOptions = ref();
        const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);
        if (router.currentRoute.params.id) {
            slideShowId.value = router.currentRoute.params.id;
            unEditable.value = true;
        }

        // fill Form When edit page
        const showData = async () => {
            const response = await showSlideshow(slideShowId.value);
            if (response) {
                form.value = { ...blankForm, ...response };
                blankForm = { ...blankForm, ...response };
                fetchTypeList(response.slidable_type);
                console.log(response);
                console.log(form.value);
            } else {
                form.value = undefined;
            }
        };

        if (slideShowId.value) {
            showData();
        }

        const onSubmit = async () => {
            console.log(form.value);
            if (form.value.slidable_type == "advertise") {
                form.value.slidable_id = null;
            } else {
                form.value.link = null;
            }
            //
            if (fileForm.value.file) {
                await storeFile();
            }
            if (slideShowId.value) {
                updateSlideshow(slideShowId.value, form.value).then((res) => {
                    router.push({ name: "apps-store-slide-show-list" });
                });
            } else {
                storeSlideShow(form.value).then((res) => {
                    router.push({ name: "apps-store-slide-show-list" });
                });
            }
        };

        const fetchTypeList = (type) => {
            if (type == "artist") {
                fetchArtists();
            } else if (type == "music") {
                fetchMusics();
            } else if (type == "video") {
                fetchVideos();
            } else if (type == "playlist") {
                fetchPlaylist();
            } else if (type == "composer") {
                fetchComposer();
            }
        };
        const fillStoreItems = () => {
            storeOptions.value = []
            const filters = {
                type: {
                    type: "like",
                    val: searchTypeItem.value,
                },
            };
            const params = {
                filters,
                pagination: {
                    page: 1,
                },
            };
            fetchStoreItems(params);
        };
        const filterStoreItems = (search) => {
            const filters = {
                name: {
                    type: "like",
                    val: search,
                },
                type: {
                    type: "like",
                    val: searchTypeItem.value,
                },
            };
            const params = {
                filters,
                pagination: {
                    page: 1,
                },
            };
            isLoading.value = true;
            try {
                fetchStoreItems(params);
            } catch (err) {
                console.log(err, "there was an error loading models");
            } finally {
                isLoading.value = false;
            }
        };

        const fetchStoreItems = async (param = {}) => {
            const resource = await storeRepository.slideShowIndex(param);
            storeOptions.value = resource.data;
        };

        return {
            form,
            fileForm,
            onSubmit,
            refFormObserver,
            getValidationState,
            resetForm,
            required,
            slideShowId,
            sourceOption,
            isLoading,
            fetchTypeList,
            artistOption,
            musicOption,
            videoOption,
            playlistOption,
            composerOption,

            storeOptions,
            unEditable,
            searchType,
            searchTypeItem,
            filterStoreItems,
            fillStoreItems
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

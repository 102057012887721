<template>
  <basic-modal
    tag="form"
    size="md"
  >
    <template #header>
      <div class="close__modal">
        add song
      </div>
    </template>
    <template #default>
      <b-row>
        <!-- add song -->
        <b-col cols="12">
          <b-form-group
            label="add songs to play list"
            label-for="add_song"
          >
            <v-select

              v-model="userData.s"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="Options"
              :reduce="(val) => val.name"
              multiple
              input-id="add_song"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
    <template #footer>
      <div class="d-flex justify-content-between align-items-center w-100">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-danger"
          @click="$_closeModal()"
        >
          cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          type="submit"
        >
          add
        </b-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import {
    BButton, BFormGroup, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
    name: 'ModalAddSong',
    components: {
        BButton,
        BFormGroup,
        BRow,
        BCol,
        vSelect,
    },
    directives: {
        Ripple,
    },
    data: () => ({
        Options: [
            {
                id: 1,
                label: 'sdf',
                name: 'baran',
                name_ar: 'الباران',
                description: 'barabn mibarad',
                lyric: 'barabn mibarad',
                genre_id: 1,
                artist_id: 1,
                length: '50',
                played_count: null,
                genre: {
                    id: 1,
                    name: 'dram',
                    name_ar: 'درام',
                    image: '',
                    thumbnail: '',
                },
                artist: {
                    id: 10,
                    name: 'saeid khakbazan',
                    name_ar: null,
                    first_name_length: '5',
                    first_name: 'saeid',
                    last_name: 'khakbazan',
                    email: 'saeid@gmail.com',
                    email_verified_at: null,
                    is_artist: 1,
                    cell_number: null,
                    city_id: null,
                    country_id: null,
                    image: '',
                    thumbnail: '',
                },
                music: '',
                image: '',
                thumbnail: '',
                hasFavorite: false,
            },
        ],
        userData: {},
    }),
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
